import { jsx as _jsx } from "react/jsx-runtime";
import { Col } from "antd";
import React from "react";
const SAColumn = ({ align = "center", justify = "flex-start", direction = "column", span = 24, children, ...rest }) => (_jsx(Col, { style: {
        display: "flex",
        alignItems: align === "end" || align === "start" ? "flex-" + align : align,
        justifyContent: justify === "end" || justify === "start" ? "flex-" + justify : justify,
        flexDirection: direction,
    }, span: span, ...rest, children: children }));
export default SAColumn;
